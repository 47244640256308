import { firstByClass, byClass } from 'OK/utils/dom';
import { isElementInViewport } from 'OK/utils/utils';
import throttle from 'OK/utils/throttle';
import Delegate from 'OK/utils/delegate';
import SpriteAnimation from 'OK/SpriteAnimation';

export default class GiftToFriend {
    /**
     * @method Активация модуля
     * @param {HTMLElement} element
     */
    activate(element) {
        this.hook = element;
        this.container = firstByClass(this.hook, 'gift-to-friend_cnt');
        this.sendBtn = firstByClass(this.hook, 'js-send-btn');
        this.previewHolder = firstByClass(this.hook, 'gift-to-friend_preview_stub');
        this.giftCards = byClass(this.hook, 'gift-card');

        this.delegator = new Delegate(this.hook);
        this.spriteAnimation = null;

        if (this.giftCards) {
            var defaultGift = this.giftCards[3];
            if (defaultGift) {
                this.choosedGift = firstByClass(defaultGift, 'gift_a');
            }
        }

        this.initHandlers();
    }

    /**
     * @method Иницилизация обработчиков
     */
    initHandlers() {
        var _this = this;
        this.delegator.on('mousedown', '.gift-card:not(.__clone)', function () {
            _this.hook.classList.remove('__demo');
            _this.hook.classList.add('__has-choice');

            // если пользователь уже определился с подарком, то и анимация не нужна
            if (this.classList.contains('__no-anim')) {
                return;
            }

            // удалим прошлый выбор
            if (_this.choosedGift) {
                var prevChoice = firstByClass(_this.hook, '__clone');
                if (prevChoice) {
                    firstByClass(_this.hook, '__clone').remove();
                }
            }

            var currentTopOffset = this.offsetTop;
            var currentLeftOffset = this.offsetLeft;

            var resultTopOffset = _this.previewHolder.offsetTop;
            var resultLeftOffset = _this.previewHolder.offsetLeft;

            var diffTop = resultTopOffset - currentTopOffset;
            var diffLeft = resultLeftOffset - currentLeftOffset;

            var clone = this.cloneNode(true);
            clone.classList.add('__clone');
            clone.style.top = currentTopOffset + 'px';
            clone.style.left = currentLeftOffset + 'px';

            _this.giftCards.forEach(function (value) {
                value.classList.remove('__hidden');
            });

            // скроем настоящий и покажем клон
            this.classList.add('__hidden');
            _this.container.appendChild(clone);
            _this.previewHolder.classList.add('__has-gift');

            setTransform(clone, 'translate(0,0)');
            setTimeout(function () {
                setTransform(clone, 'translate(' + diffLeft + 'px, ' + diffTop + 'px)');
            }, 200);

            // если подарок анимированный, воспроизведём
            var giftImg = clone.firstChild;
            if (giftImg.classList.contains('__spriteAnimation')) {
                _this.spriteAnimation = new SpriteAnimation();
                _this.spriteAnimation.activate(giftImg);
            }

            // запомним выбор
            _this.choosedGift = firstByClass(this, 'gift_a');
            _this.sendBtn.classList.remove('__disabled');
        });

        this.sendBtnListener = function () {
            if (this.choosedGift) {
                this.choosedGift.classList.add('__no-anim');
                this.choosedGift.click();
            }
        }.bind(this);
        this.sendBtn.addEventListener('click', this.sendBtnListener);

        this.checkVisibility = throttle(150, function (e) {
            if (this.hook.classList.contains('__has-choice')) {
                window.removeEventListener("scroll", this.checkVisibility);
            } else {
                this.hook.classList.toggle('__demo', isElementInViewport(this.hook));
            }
        }.bind(this));
        this.checkVisibility();
        window.addEventListener("scroll", this.checkVisibility);
    }

    /**
     * @method Деактивация модуля
     */
    deactivate() {
        this.delegator.destroy();
        this.sendBtn.removeEventListener('click', this.sendBtnListener);
        window.removeEventListener("scroll", this.checkVisibility);
    }
}


function setTransform(el, transform) {
    el.style.cssText += "; transform: " + transform
        + "; -webkit-transform: " + transform
        + "; -moz-transform: " + transform
        + "; -ms-transform: " + transform
        + ";";
}
